import React, {useEffect, useState, Suspense} from 'react';
import { Button, Flex, Input, Checkbox, Icon, Text } from "@front-web/design";
import { useTranslation } from 'react-i18next';
// import of files
import './App.css';
import illustration from './images/server-down-illustration.png';
import logo from './images/logo-he.png';
import iconFacebook from './images/icon-facebook.png';
import iconInsta from './images/icon-insta.png';
import iconStore from './images/icon-store.png';
import iconApple from './images/icon-apple.png';

// process.env.REACT_APP_API_URL;
// const API_URL = 'https://dtb3bu77s6.execute-api.eu-west-1.amazonaws.com/v1';
const API_URL = 'https://x4z69aanu1.execute-api.eu-west-1.amazonaws.com/v1/';

function App() {
    const {t} = useTranslation();
    return (
    <div className='mainContainer'>
        <div style={{'backgroundImage': `url(${illustration})`}} className='illustration'></div>
        <div className='content'>
            <div>
                <img src={logo} alt='Home Exchange' />
            </div>
            <h1>{t('server-down-title')}</h1>
            <p>{t('server-down-baseline')}</p>
            <Form />
            <SocialBlock />
        </div>
    </div>
    );
}

const Form = () => {
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const [valid, setValid] = useState(false);
    const [consent, setConsent] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [status, setStatus] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValid(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }, [email, setValid]);

    useEffect(() => {
        setError(data.error);
        setSuccess(data.message);
        setLoading(false);
    }, [data, setError, setSuccess]);

    useEffect(() => {
        setStatus(success ? 'success' : error ? 'error' : null);
    }, [success, error, setStatus]);

    const handleEmail = (value) => {
        setEmail(value)
        setError(false)
    }

    const handleSubmit = (e) => {
        // call api with get and path /email/{email}
        setLoading(true);
        fetch(`${API_URL}/email`, {
            method: 'POST',
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email})
        })
            .then(response => response.json())
            .then(data => setData(data))
            .catch((error) => {
                setData({error: 'unknown error'});
            }
        );
    }

    return <form>
        {success 
        ? <>
            <div className='success'>
                <p>
                <Icon name='checkbox-fill' color='success' size='5xl' />
                </p>
                <Text color='success'>
                    {t('server-down-form-success')}
                </Text>
            </div>
        </>
        :
        <div>
            <p>{t('server-down-form-title')}</p>
            <p>
                <Input 
                    type='text' 
                    name='email'
                    className="textField" 
                    label={t('server-down-form-placeholder')} 
                    placeholder={t('server-down-form-placeholder')} 
                    legend={status === "error" ? t('server-down-form-error') : status === "success" ? t('server-down-form-success') : null}
                    onChange={e => handleEmail(e.target.value)}
                    value={email}
                    status={status}
                    isFullWidth={true}
                />
                <Checkbox 
                    label={t('server-down-form-check-label')}
                    onChange={e => setConsent(prev => e.target.checked)}
                    checked={consent}
                />

            </p>
            <p>
                <Button onClick={handleSubmit} disabled={!valid || !consent || loading || error} color='primary' isFullWidth={true}>
                    {t('server-down-form-cta')}
                </Button>
            </p>
        </div>
        }
    </form>
}

const SocialBlock = ({}) => {
    const {t} = useTranslation();
    return <>
        <p>{t('server-down-form-check-title')}</p>
        <Flex direction='row'>
            <SocialButton link={t('Facebook link')} name='Facebook' image={iconFacebook} />
            <SocialButton link={t('Instagram link')} name='Instagram' image={iconInsta} />
            <SocialButton link={t('AppStore link')} name='App Store' image={iconApple} />
            <SocialButton link={t('Google PlayStore link')} name='Google Play' image={iconStore} />
        </Flex>
    </>
}

const SocialButton = ({link, name, image}) => {
    return <div>
        <a href={link} target='_blank'>
            <img src={image} alt={name} width={30} height={30} />
        </a>
    </div>
}

export default function WrappedApp() {
    return (
        <Suspense fallback="...loading">
            <App />
        </Suspense>
    )
}
